import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { userAtom } from '../../store';
import CustomAxios from '../common/CustomAxios';
import {
    listenAuthState,
    signInAnonymouslyUser,
    signInWithGoogle,
    signInWithEmail,
    signOutUser,
} from '../sns/google/firebase';

const useLogin = () => {
    const [user, setUser] = useAtom(userAtom);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        const handleAuthStateChange = async (currentUser) => {
            if (currentUser) {
                await getUserInfo(currentUser);
            } else {
                await handleAnonymous();
            }
        };

        const unsubscribe = listenAuthState(handleAuthStateChange);
        return () => unsubscribe();
    }, []);

    const handleAnonymous = async () => {
        try {
            const anonymousUser = await signInAnonymouslyUser();
            setUser({
                isAnonymous: anonymousUser.isAnonymous,
                uid: anonymousUser.uid,
            });
        } catch (error) {
            console.error('익명 로그인 실패:', error);
        }
    };

    const getUserInfo = async (currentUser) => {
        try {
            const searchParams = new URLSearchParams();
            searchParams.append('email', currentUser.email);

            const response = await CustomAxios(`/getUser?${searchParams.toString()}`);

            if (response.data.error === 'User is blocked') {
                alert('차단된 사용자입니다. 로그인이 불가능합니다.');
                await signOut();
                return;
            }

            if (response.data.message === 'User not found, signup required') {
                setUser((prevUser) => ({
                    ...prevUser,
                    email: currentUser.email,
                }));
                setShowModal(true);
            } else {
                setUser(response.data.result);
            }
        } catch (error) {
            console.error('사용자 정보를 가져오는 중 오류 발생:', error);
            // alert('사용자 정보를 가져오는 중 문제가 발생했습니다. 다시 시도해주세요.');
        }
    };

    const handleEmailLogin = async (email, password) => {
        if (!email || !password) {
            setError('이메일과 비밀번호를 입력해주세요.');
            return;
        }

        if (!/^[^@]+@[^@]+\.[^@]+$/.test(email)) {
            setError('유효한 이메일 주소를 입력해주세요.');
            return;
        }

        if (password.length < 6) {
            setError('비밀번호는 최소 6자 이상이어야 합니다.');
            return;
        }

        try {
            setError(''); // Clear any previous errors
            await handleEmailLogin(email, password);
        } catch (err) {
            if (err.code === 'auth/invalid-email') {
                setError('유효하지 않은 이메일 주소입니다.');
            } else if (err.code === 'auth/user-not-found') {
                setError('등록되지 않은 사용자입니다.');
            } else if (err.code === 'auth/wrong-password') {
                setError('잘못된 비밀번호입니다.');
            } else {
                setError('로그인 실패: 다시 시도해주세요.');
            }
        }
    };

    const handleGoogleSnsLogin = async () => {
        try {
            const currentUser = await signInWithGoogle();
            await getUserInfo(currentUser);
        } catch (error) {
            if (error.code === 'auth/popup-closed-by-user') {
                alert('로그인 팝업이 닫혔습니다.');
            } else if (error.code === 'auth/cancelled-popup-request') {
                alert('로그인 요청이 취소되었습니다. 다시 시도해주세요.');
            } else {
                console.error('로그인 중 오류 발생:', error);
                alert('로그인 중 문제가 발생했습니다.');
            }
        }
    };

    const signOut = async () => {
        await signOutUser();
        setUser(null);
    };

    return {
        user,
        showModal,
        error,
        handleEmailLogin,
        handleGoogleSnsLogin,
        signOut,
        setShowModal,
    };
};

export default useLogin;
