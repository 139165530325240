import React from "react";
import { Grid, IconButton, TextField, Box, Button } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const LinkForm = ({ links, handleLinkChange, handleRemoveLink }) => (
  <>
    {links.map((link, index) => (
      <Grid container spacing={2} key={index} sx={{alignItems: 'center'}}>
        <Grid item xs={11}>
          <TextField
            sx={{ mt: "10px" }}
            variant="outlined"
            fullWidth
            size="small"
            label="링크 URL"
            value={link.url}
            onChange={(e) => handleLinkChange(index, e)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => handleRemoveLink(index)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </>
);

export default LinkForm;