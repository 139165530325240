import React, { useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './customStyles.css';  // 커스텀 CSS 파일을 import 합니다.
import { format, parse, startOfWeek, getDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko'; // 'ko-KR' 대신 'ko'
import CustomAxios from "../common/CustomAxios";
import CustomToolbar from './CustomToolbar'; // 커스텀 Toolbar 컴포넌트 임포트

const locales = {
  'ko': ko,
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }),
  getDay,
  locales,
});

const MyCalendar = ({ uid }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('month'); // 현재 view 저장
  const [allEvents, setAllEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const fetchDiaryEntries = async () => {
      try {
        const response = await CustomAxios.get(`/getDiaries?uid=${uid}`);
        
        if (response.data != null && response.data.result.length !== 0) {
          const diaryEntries = response.data.result.map((entry) => {
            const startDateTime = new Date(`${entry.startDate}T${entry.startTime}`);
            const endDateTime = new Date(`${entry.endDate}T${entry.endTime}`);
            
            return {
              title: entry.content,
              start: startDateTime,
              end: endDateTime,
              type: entry.type,
            };
          });
          setAllEvents(diaryEntries);
        }
      } catch (error) {
        console.error("Error fetching diary entries:", error);
      }
    };

    if (uid) {
      fetchDiaryEntries();
    }
  }, [uid]);

  const handleNavigate = (newDate) => {
    setCurrentDate(newDate);
    setSelectedDate(null); // navigation 시 선택 초기화
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedDate(slotInfo.start);
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    let backgroundColor;

    switch (event.type) {
      case '조깅':
        backgroundColor = 'lightblue';
        break;
      case '장거리':
        backgroundColor = 'lightgreen';
        break;
      case '슬로우 조깅':
        backgroundColor = 'lightpink';
        break;
      case '인터벌':
        backgroundColor = 'lightcoral';
        break;
      case '휴식':
        backgroundColor = 'lightgray';
        break;
      case '지속주':
        backgroundColor = 'lightgoldenrodyellow';
        break;
      case '기록체크':
        backgroundColor = 'lightseagreen';
        break;
      case 'TT':
        backgroundColor = 'lightsalmon';
        break;
      default:
        backgroundColor = 'lightblue';
    }
    
    let newStyle = {
      backgroundColor,
      color: 'black',
      borderRadius: '0px',
      border: 'none',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };

    if (isSelected) {
      newStyle.backgroundColor = 'lightgreen';
    }

    return {
      className: '',
      style: newStyle
    };
  };

  const dateCellWrapper = ({ value, children }) => {
    let wrapperStyle = {};
    
    if (selectedDate && value && value.toDateString() === selectedDate.toDateString()) {
      wrapperStyle = { backgroundColor: 'lightgreen' };
    } else if (value && value.toDateString() === new Date().toDateString()) {
      wrapperStyle = { backgroundColor: 'lightblue' };
    }
    
    return React.cloneElement(children, {
      style: { ...children.props.style, ...wrapperStyle }
    });
  };

  const eventComponent = ({ event }) => {
    return (
      <span>{event.title}</span> // 시간정보 숨기기
    );
  };

  return (
    <div style={{ height: 800 }}>
      <Calendar
        localizer={localizer}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        date={currentDate}
        onNavigate={handleNavigate}
        view={view}
        onView={handleViewChange}
        defaultView="month"
        views={['month', 'week', 'day', 'agenda']}
        selectable
        onSelectEvent={(event) => alert(event.title)}
        onSelectSlot={handleSelectSlot}
        culture='ko' // 한국어 로케일 설정
        selected={selectedDate}
        eventPropGetter={eventPropGetter}
        components={{
          dateCellWrapper,
          toolbar: CustomToolbar,
          event: eventComponent,   // 이벤트 컴포넌트 설정
        }}
        messages={{
          date: '날짜',
          time: '시간',
          event: '훈련일지',
          allDay: '종일',
          week: '주',
          work_week: 'work week',
          day: '일',
          month: '월',
          previous: '이전',
          next: '다음',
          yesterday: '어제',
          tomorrow: '내일',
          today: '오늘',
          agenda: '훈련일지',
          noEventsInRange: '훈련일지를 작성하세요.',
          showMore: total => `+${total}`,
        }}
        formats={{
          dateFormat: 'd',
          dayFormat: (date, culture, localizer) =>
            localizer.format(date, 'd일 (EEE)', culture),
          dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
            `${localizer.format(start, 'yyyy년 M월 d일', culture)} — ${localizer.format(end, 'yyyy년 M월 d일', culture)}`,
          dayRangeFormat: ({ start, end }, culture, localizer) =>
            `${localizer.format(start, 'yyyy년 M월 d일', culture)} — ${localizer.format(end, 'yyyy년 M월 d일', culture)}`,
          dayHeaderFormat: 'yyyy년 M월 d일',
          agendaHeaderFormat: ({ start, end }, culture, localizer) =>
            `${localizer.format(start, 'yyyy년 M월 d일', culture)} - ${localizer.format(end, 'yyyy년 M월 d일', culture)}`,
          agendaDateFormat: 'M월 d일 (EEE)',
          agendaTimeFormat: 'HH:mm',
          agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
            `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
          eventTimeRangeFormat: () => '',  // 주를 표시할때 시간 정보 숨기기
          eventTimeRangeEndFormat: () => '',  // 주를 표시할때 시간 정보 숨기기
          timeGutterFormat: 'HH:mm'
        }}
      />
    </div>
  );
};

export default MyCalendar;