import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  Button,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import CustomAxios from "../../common/CustomAxios";
import ShoeAddModal from "./ShoeAddModal";
import ShoeViewModal from "./ShoeViewModal";

const shoesModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ShoeManagementModal = ({ open, handleClose, uid, setShoes }) => {
  const [selectedShoe, setSelectedShoe] = useState(null);
  const [shoes, setShoesState] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // 추가: 로딩 상태

  useEffect(() => {
    if (open) {
      fetchShoes();
    }
  }, [open]);

  const fetchShoes = async () => {
    setLoading(true); // 로딩 시작
    try {
      const response = await CustomAxios.get(`/getShoesByUser?uid=${uid}`);
      setShoesState(response.data.shoes);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false); // 로딩 종료
    }
  };

  const handleSelectShoe = (shoe) => {
    setSelectedShoe(shoe);
    setViewModalOpen(true);
  };

  const handleAddModalClose = () => {
    setAddModalOpen(false);
    fetchShoes();
  };

  const handleViewModalClose = () => {
    setViewModalOpen(false);
    setSelectedShoe(null);
    fetchShoes();
  };

  const handleDeleteShoe = async (shoeId) => {
    try {
      await CustomAxios.post(`/deleteShoe`, { uid, shoeId, use_yn: "N" });
      fetchShoes();
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateShoe = async (shoeId, name, useYN, defaultYN, distance, purchaseDate) => {
    try {
      await CustomAxios.post(`/updateShoe`, {
        uid, 
        shoeId, 
        name, 
        useYN, 
        defaultYN,
        distance: parseFloat(distance),
        purchaseDate,
      });
      fetchShoes();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={shoesModalStyle}>
        <Typography
          variant="h6"
          display="flex"
          justifyContent="space-between"
        >
          신발 관리
          <Button variant="outlined" onClick={() => setAddModalOpen(true)}>
            추가
          </Button>
        </Typography>
        <Divider sx={{ my: 2 }} />
        {loading ? ( // 로딩 상태일 때
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : shoes.length === 0 ? (
          <Typography>신발을 등록하세요.</Typography>
        ) : (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {shoes.map((shoe) => (
              <Grid item xs={12} key={shoe.id}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box
                    component="span"
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    onClick={() => handleSelectShoe(shoe)}
                  >
                    {shoe.name}
                  </Box>
                  <IconButton
                    onClick={() => handleDeleteShoe(shoe.id)}
                    sx={{ color: "red" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        <ShoeAddModal open={addModalOpen} handleClose={handleAddModalClose} uid={uid} onSuccess={fetchShoes} />
        {selectedShoe && (
          <ShoeViewModal
            open={viewModalOpen}
            handleClose={handleViewModalClose}
            shoe={selectedShoe}
            onDelete={handleDeleteShoe}
            onUpdate={handleUpdateShoe}
            initialUseYN={selectedShoe.use_yn}
            initialDefaultYN={selectedShoe.default_yn}
            initialDistance={selectedShoe.distance}
            initialPurchaseDate={selectedShoe.purchase_date}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ShoeManagementModal;