import { Container } from '@mui/material';
import React from 'react';

const PersonalDataConsentForm250228v1 = () => {
  return (
    <Container
                maxWidth="md"
                sx={{
                    py: 4,
                    backgroundColor: 'background.paper',
                    border: '1px solid #000',
                    boxShadow: 24,
                    overflow: 'auto',
                    maxHeight: '100vh',
                    lineHeight: 1.6,
                    '& ol, ul': { pl: 3, my: 2 },
                    '& li': { mb: 1 },
                    '& .provision_title': { fontSize: '1.1rem', fontWeight: 600, mt: 4, mb: 2 }
                }}
            >
                <p style={{
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    padding: '52px 0 40px',
                    margin: 0
                }}>
        개인정보처리동의서
      </p>

      <p>
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>러닝다이어리</span>
        (이하 '회사'라고 합니다)는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하며 
        귀하의 개인정보보호에 최선을 다하고 있습니다. 회사는 개인정보보호법에 근거하여 다음과 같은 
        내용으로 개인정보를 수집 및 처리하고자 합니다.
      </p>

      <br />

      <p>다음의 내용을 자세히 읽어보시고 모든 내용을 이해하신 후에 동의 여부를 결정해주시기 바랍니다.</p>

      {/* 제1조 */}
      <p className="provision_title">제1조(개인정보 수집 및 이용 목적)</p>
      <p>이용자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 목적 이외의 용도로는 사용되지 않습니다.</p>
      <p>- <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>본인확인</span></p>

      {/* 제2조 */}
      <p className="provision_title">제2조(개인정보 수집 및 이용 항목)</p>
      <p>회사는 개인정보 수집 목적을 위하여 다음과 같은 정보를 수집합니다.</p>
      <p>- <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>이메일</span></p>

      {/* 제3조 */}
      <p className="provision_title">제3조(개인정보 보유 및 이용 기간)</p>
      <ol>
        <li>
          수집한 개인정보는 수집·이용 동의일로부터&nbsp;
          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>5</span>년간&nbsp;
          보관 및 이용합니다.
        </li>
        <li>
          개인정보 보유기간의 경과, 처리목적의 달성 등 개인정보가 불필요하게 되었을 때에는 
          지체없이 해당 개인정보를 파기합니다.
        </li>
      </ol>

      {/* 제4조 */}
      <p className="provision_title">제4조(동의 거부 관리)</p>
      <p>
        귀하는 본 안내에 따른 개인정보 수집·이용에 대하여 동의를 거부할 권리가 있습니다. 다만, 
        귀하가 개인정보 동의를 거부하시는 경우에&nbsp;
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
          서비스 이용 중 일부 제한
        </span>
        의 불이익이 발생할 수 있음을 알려드립니다.
      </p>

      <p>
        <br />
        본인은 위의 동의서 내용을 충분히 숙지하였으며, 위와 같이 개인정보를 수집·이용하는데 동의합니다.
      </p>

      <p style={{ textAlign: 'right' }}>
        <br />
        개인정보 수집 및 이용에 동의함 □&nbsp; 동의하지 않음 □&nbsp;
      </p>

      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <strong>&nbsp;2025년 02월 28일</strong>
      </div>
    </Container>
  );
};

export default PersonalDataConsentForm250228v1;