import React from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import { CloudUpload as CloudUploadIcon, Close as CloseIcon, ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";

const ImageUpload = ({ images, setImages, handleDrop, handleImageRemove, handleMoveImageLeft, handleMoveImageRight }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    multiple: true,
  });

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed grey",
          p: 2,
          marginTop: 2,
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>
            업로드 할 파일을 여기에 파일을 끌어다 놓으세요.
          </Typography>
        ) : (
          <Typography>
            업로드 할 파일을 여기에 파일을 끌어다 놓거나 이 영역을
            클릭하여 파일을 선택 하세요.
          </Typography>
        )}
        <CloudUploadIcon fontSize="large" />
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {images.map((image, index) => (
          <Grid item key={image.id}>
            <Box position="relative" display="inline-block">
              <img
                src={URL.createObjectURL(image.file)}
                alt={`upload-${index}`}
                width={100}
                height={100}
                style={{ objectFit: "cover" }}
              />
              <Box
                position="absolute"
                top={0}
                left={0}
                bgcolor="rgba(0, 0, 0, 0.5)"
                color="white"
                p={0.5}
              >
                {index + 1}
              </Box>
              <IconButton
                color="inherit"
                onClick={() => handleImageRemove(index)}
                sx={{ position: "absolute", top: 0, right: 0, color: "#000" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box display="flex" justifyContent="center" mt={1}>
              {index > 0 && (
                <IconButton onClick={() => handleMoveImageLeft(index)}>
                  <ArrowBackIosIcon />
                </IconButton>
              )}
              {index < images.length - 1 && (
                <IconButton onClick={() => handleMoveImageRight(index)}>
                  <ArrowForwardIosIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ImageUpload;