import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Card,
  CardContent,
  CardActions, // eslint-disable-line no-unused-vars
  List,
  ListItem,
  ListItemText,
  Select, MenuItem,
} from '@mui/material';
import TimestampFormatter from "../common/TimestampFormatter";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageResize from 'quill-image-resize';
import CustomAxios from "../common/CustomAxios";
import { dummyCategoryList, isLoadingAtom, userAtom } from "../../store";
import { useAtom } from "jotai";
import { EditNoteOutlined, EditOutlined, MemoryOutlined, SaveOutlined, SendOutlined } from "@mui/icons-material";
import { checkLogin } from "../login/checkLogin";

// 모듈을 한 번만 등록
if (!Quill.imports['modules/imageResize']) {
  Quill.register('modules/imageResize', ImageResize);
}


// 폰트 옵션 추가
const Font = Quill.import('formats/font');
Font.whitelist = ['my-font', 'sans-serif', 'serif']; // 필요한 폰트 추가
Quill.register(Font, true);

// Quill 모듈 설정
const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    [{ 'font': Font.whitelist }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ['link', 'image', 'video'],
  ],
  clipboard: {
    matchVisual: false
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize', 'Toolbar']
  }
};

const PostCreate = () => {
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const [ready, setReady] = useState(false);
  const quillRef = useRef();
  const [category, setCategory] = useState(1);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (user) {
      setReady(true);
    }
    
  }, [user]);

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const onClickSave = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }
    
    setIsLoading(true);

    // console.log(user);

    CustomAxios(`/createPost`, { // Replace with your API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed, e.g., authorization
      },
      data: JSON.stringify({
        category: category,
        title: title,
        content: content,
        uid: user.uid,
        nick_name: user.nick_name
      }),
    })
      .then((result) => {
        // console.log(result);

        if (result.status == 201) {
          // const res = result.data;
          alert("등록 되었습니다.");
          onClickHistoryBack();
        }
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setIsLoading(false);
      })
      ;
  }, [user, category, title, content]);

  const onClickHistoryBack = () => {
    navigate(-1);
  }

  if (!ready) {
    return (<div></div>);
  }

  return (
    <>
      <Box my={2}>
        <Typography variant="subtitle2" component="div" style={{ display: 'flex', alignItems: 'center' }}>
          카테고리
          <Select style={{ marginLeft: 10 }} value={category} size="small" onChange={handleChangeCategory}>
            {
              dummyCategoryList.map(item => {
                if (item.key !== 0) {
                  return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>;
                }
              })
            }
          </Select>
        </Typography>
        <TextField
          sx={{ mt: "10px" }}
          variant="outlined"
          fullWidth
          size="small"
          label="타이틀"
          value={title}
          onChange={handleChangeTitle}
        />
        <Divider sx={{ my: 2 }} />

        {/* Quill Editor를 사용하여 내용 표시 */}
        <ReactQuill
          theme="snow"
          modules={modules}
          ref={quillRef}
          value={content}
          onChange={setContent}
          className="custom-quill-editor"
        />

        <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={onClickHistoryBack}><Typography variant='button'>이전</Typography></Button>
          <Button variant="outlined" startIcon={<SendOutlined />} onClick={onClickSave}><Typography variant='button'>등록</Typography></Button>
        </Box>
      </Box>
    </>
  );
};

export default PostCreate;