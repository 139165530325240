import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { userAtom } from '../../store';
import CustomAxios from '../common/CustomAxios';
import { listenAuthState, signInAnonymouslyUser, signInWithGoogle, signOutUser } from '../sns/google/firebase';
import Join from '../login/Join';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const [user, setUser] = useAtom(userAtom);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const handleAuthStateChange = async (currentUser) => {
            // console.log(currentUser);
            if (currentUser) {
                try {
                    const searchParams = new URLSearchParams();
                    searchParams.append("email", currentUser.email);

                    const response = await CustomAxios(`/getUser?${searchParams.toString()}`);

                    if (response.data.error === "User is blocked") {
                        alert("차단된 사용자입니다. 로그인이 불가능합니다.");

                    } else if (response.data.message === "User not found, signup required") {
                        // 회원가입 필요하더라도 모달을 바로 띄우지 않음
                        setUser((prevUser) => ({
                            ...prevUser,
                            uid: currentUser.uid,
                            email: currentUser.email,
                        }));
                    } else {
                        // 정상 사용자 정보 저장
                        setUser(response.data.result);
                    }
                } catch (error) {
                    console.error("Firestore 데이터 확인 중 오류 발생:", error);
                    //alert("사용자 정보를 가져오는 중 문제가 발생했습니다. 다시 시도해주세요.");
                }
            } else {
                // 로그인된 사용자가 없으면 익명 로그인 수행
                await handleAnonymous();
            }
        };

        // Firebase 인증 상태 변경을 감지
        const unsubscribe = listenAuthState(handleAuthStateChange);

        // 컴포넌트 언마운트 시 구독 해제
        return () => unsubscribe();
    }, []);

    const signOut = async () => {
        await signOutUser(); // 로그아웃 처리
        setUser(null); // 사용자 정보 초기화
        await handleAnonymous();
    };

    const handleAnonymous = async () => {
        try {
            const anonymousUser = await signInAnonymouslyUser();
            setUser({
                isAnonymous: anonymousUser.isAnonymous,
                uid: anonymousUser.uid,
            });
        } catch (error) {
            console.error('익명 로그인 실패:', error);
        }
    };

    const getUserInfo = async (currentUser) => {
        // Firestore에서 사용자 정보 확인
        const searchParams = new URLSearchParams();
        searchParams.append("email", currentUser.email);

        const response = await CustomAxios(`/getUser?${searchParams.toString()}`);
        // console.log(response);

        if (response.data.error === "User is blocked") {
            alert("차단된 사용자입니다. 로그인이 불가능합니다.");
            await signOut(); // 로그아웃 처리
            return;
        }

        if (response.data.message === "User not found, signup required") {
            setUser((prevUser) => ({
                ...prevUser, // 기존 상태를 보존
                email: currentUser.email
            }));
            setTimeout(() => setShowModal(true), 0); // 회원가입 모달 표시. 상태가 업데이트된 후 모달을 띄움
        } else {
            setUser(response.data.result); // 유저 정보 저장
        }
    }

    const handleGoogleSnsLogin = async () => {
        try {
            const currentUser = await signInWithGoogle();

            await getUserInfo(currentUser);

        } catch (error) {
            if (error.code === "auth/popup-closed-by-user") {
                alert("The login popup was closed before completing the sign-in process.");
            } else if (error.code === "auth/cancelled-popup-request") {
                alert("The login popup was canceled. Please try again.");
            } else {
                console.error("Error during login:", error);
                alert("An error occurred during the login process.");
            }
        }
    };


    const goMyPage = async () => {
        navigate(`/recordList`);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <Box display='flex' justifyContent='space-between' alignItems='center'
            sx={{
                // backgroundColor: '#2222DA',
                paddingX: 1,
                paddingY: 1
            }}
        >
            <Typography
                variant="h8"
                mr={2}
                sx={{ cursor: 'pointer' }}
                onClick={goMyPage}
            >
                {user?.nick_name}
            </Typography>
            {(user?.nick_name) ?
                <Typography
                    variant="h8"
                    mr={2}
                    sx={{ cursor: 'pointer' }}
                    onClick={signOut}
                >
                    로그아웃
                </Typography>
                :
                <Typography
                    variant="h8"
                    mr={2}
                    sx={{ cursor: 'pointer' }}
                    onClick={handleGoogleSnsLogin}
                >
                    로그인
                </Typography>
            }

            <Modal open={showModal} onClose={closeModal}>
                <Box>
                    <Join setShowModal={setShowModal} />
                </Box>
            </Modal>
        </Box>
    );
};

export default Login;
