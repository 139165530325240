import React from "react";
import { Grid, IconButton, TextField, Select, MenuItem, Box, Button } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const ExerciseForm = ({ exercises, bodyweightExercises, handleExerciseChange, handleRemoveExercise }) => (
  <>
    {exercises.length > 0 && exercises.map((exercise, index) => (
      <Grid container spacing={2} key={index}>
        <Grid item xs={5} sx={{ display: 'flex', alignItems: 'center' }}>
          <Select
            style={{ marginLeft: 10 }}
            value={exercise.name}
            name="name"
            size="small"
            onChange={(e) => handleExerciseChange(index, e)}
            fullWidth
          >
            {bodyweightExercises.map((exerciseName, idx) => (
              <MenuItem key={idx} value={exerciseName}>
                {exerciseName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <TextField
            sx={{ mt: "10px" }}
            variant="outlined"
            fullWidth
            size="small"
            label="반복 횟수"
            name="reps"
            value={exercise.reps}
            onChange={(e) => handleExerciseChange(index, e)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            sx={{ mt: "10px" }}
            variant="outlined"
            fullWidth
            size="small"
            label="세트 횟수"
            name="sets"
            value={exercise.sets}
            onChange={(e) => handleExerciseChange(index, e)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => handleRemoveExercise(index)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </>
);

export default ExerciseForm;