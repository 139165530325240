import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { Box, Button, TextField, Typography, CircularProgress, Container, IconButton, Grid } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { userAtom } from '../../store';
import CustomAxios from '../common/CustomAxios';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

const RecordCreate = () => {
  const [user] = useAtom(userAtom);
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [distance, setDistance] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [milliseconds, setMilliseconds] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDrop = acceptedFiles => {
    const newImages = acceptedFiles.map((file, index) => ({
      id: `${file.name}-${Date.now()}-${index}`,
      file
    }));

    setImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleImageRemove = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleMoveImageLeft = (index) => {
    if (index === 0) return;
    const newImages = [...images];
    [newImages[index], newImages[index - 1]] = [newImages[index - 1], newImages[index]];
    setImages(newImages);
  };

  const handleMoveImageRight = (index) => {
    if (index === images.length - 1) return;
    const newImages = [...images];
    [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
    setImages(newImages);
  };

  const handleMillisecondsChange = (e) => {
    let value = e.target.value;
    
    // 공백이나 숫자인지, 또는 숫자가 0-999 사이인지 확인합니다.
    if (value === '' || (/^\d{0,3}$/.test(value) && value >= 0 && value < 1000)) {
      setMilliseconds(value);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // 값 검증 - 입력한 밀리초를 숫자로 변환하고 제한 범위 확인
    const millisecondsNumber = Number(milliseconds);
    if (isNaN(millisecondsNumber) || millisecondsNumber < 0 || millisecondsNumber >= 1000) {
      alert('밀리초 입력이 잘못되었습니다. 0 ~ 999 값으로 가능합니다.');
      setLoading(false);
      return;
    }
  
    var time = "";
    // 기본값 적용 및 밀리초 값 형식 변환
    if (milliseconds === '') {
      time = `${hours || '0'}:${minutes || '0'}:${seconds || '0'}.0`;
    } else {
      time = `${hours || '0'}:${minutes || '0'}:${seconds || '0'}.${milliseconds}`;
    }
  
    const imageEntries = await Promise.all(
      images.map(async (image) => {
        const base64 = await fileToBase64(image.file);
        return { src: base64 };
      })
    );
  
    const postData = {
      eventName,
      eventDate,
      distance,
      time,
      images: imageEntries,
      nick_name: user?.nick_name,
      uid: user?.uid,
    };
  
    try {
      const response = await CustomAxios(`/createRecord`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(postData),
      });
  
      if (response.data.result === 'SUCCESS') {
        alert('저장 되었습니다.');
        // 폼 리셋 및 추가 작업
        navigate('/recordList'); // 저장 후 목록으로 이동
      }
    } catch (error) {
      console.error('Error creating marathon record:', error);
      alert('저장시 에러가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    multiple: true
  });

  return (
    <Container>
      <Typography variant="h4" gutterBottom>내 기록 저장하기</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="대회명"
          fullWidth
          margin="normal"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          label="대회일자"
          fullWidth
          margin="normal"
          type="date"
          value={eventDate}
          onChange={(e) => setEventDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          label="참가 유형"
          fullWidth
          margin="normal"
          select
          SelectProps={{ native: true }}
          value={distance}
          onChange={(e) => setDistance(e.target.value)}
          required
          InputLabelProps={{ shrink: true }}
        >
          <option value="">선택하세요.</option>
          <option value="10km">10km</option>
          <option value="21.0975km">Half Marathon</option>
          <option value="30km">30km</option>
          <option value="32km">32km</option>
          <option value="42.195km">Marathon</option>
        </TextField>
        <Typography variant="body1" marginTop="normal">기록</Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <NumericFormat
            InputLabelProps={{ shrink: true }}
            customInput={TextField}
            label="시"
            value={hours}
            onValueChange={(values) => setHours(values.value)}
            placeholder="0"
            format="##"
            style={{ width: '20%' }}
            allowNegative={false}
            isAllowed={(val) => {
              return val.value === '' || (val.floatValue >= 0 && val.floatValue < 400);
            }}
          />
          <NumericFormat
            InputLabelProps={{ shrink: true }}
            customInput={TextField}
            label="분"
            value={minutes}
            onValueChange={(values) => setMinutes(values.value)}
            placeholder="0"
            format="##"
            style={{ width: '20%' }}
            allowNegative={false}
            isAllowed={(val) => {
              return val.value === '' || (val.floatValue >= 0 && val.floatValue < 60);
            }}
          />
          <NumericFormat
            InputLabelProps={{ shrink: true }}
            customInput={TextField}
            label="초"
            value={seconds}
            onValueChange={(values) => setSeconds(values.value)}
            placeholder="0"
            format="##"
            style={{ width: '20%' }}
            allowNegative={false}
            isAllowed={(val) => {
              return val.value === '' || (val.floatValue >= 0 && val.floatValue < 60);
            }}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="밀리초"
            value={milliseconds}
            onChange={handleMillisecondsChange}
            placeholder="000"
            format="###"
            style={{ width: '20%' }}
          />
        </Box>

        <Box {...getRootProps()} sx={{ border: '2px dashed grey', p: 2, marginTop: 2, textAlign: 'center' }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>업로드 할 파일을 여기에 파일을 끌어다 놓으세요.</Typography>
          ) : (
            <Typography>업로드 할 파일을 여기에 파일을 끌어다 놓거나 이 영역을 클릭하여 파일을 선택 하세요.</Typography>
          )}
          <CloudUploadIcon fontSize="large" />
        </Box>

        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {images.map((image, index) => (
            <Grid item key={image.id}>
              <Box position="relative" display="inline-block">
                <img src={URL.createObjectURL(image.file)} alt={`upload-${index}`} width={100} height={100} style={{ objectFit: 'cover' }} />
                <Box position="absolute" top={0} left={0} bgcolor="rgba(0, 0, 0, 0.5)" color="white" p={0.5}>
                  {index + 1}
                </Box>
                <IconButton
                  color="inherit"
                  onClick={() => handleImageRemove(index)}
                  sx={{ position: 'absolute', top: 0, right: 0, color: '#000' }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box display="flex" justifyContent="center" mt={1}>
                {index > 0 && (
                  <IconButton onClick={() => handleMoveImageLeft(index)}>
                    <ArrowBackIosIcon />
                  </IconButton>
                )}
                {index < images.length - 1 && (
                  <IconButton onClick={() => handleMoveImageRight(index)}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="outlined" onClick={() => navigate('/RecordList')}>
            목록
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : '저장'}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default RecordCreate;