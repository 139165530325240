import React from "react";
import { Select, MenuItem, Typography } from "@mui/material";

const FormSelect = ({ label, value, options, onChange }) => (
  <>
    {label ? (
      <Typography variant="subtitle2" component="div" style={{ display: "flex", alignItems: "center" }}>
        {label}
        <Select
          style={{ marginLeft: 10, minWidth: 100 }}
          value={value}
          size="small"
          onChange={onChange}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Typography>
    ) : (
      <Select
        style={{ minWidth: 100 }}
        value={value}
        size="small"
        onChange={onChange}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    )}
  </>
);

export default FormSelect;