import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Modal,
  List,
  CircularProgress,
} from '@mui/material';
import TimestampFormatter from "../common/TimestampFormatter";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageResize from 'quill-image-resize';
import CustomAxios from "../common/CustomAxios";
import { useAtom } from "jotai";
import { isLoadingAtom, userAtom, readPostAtom, dummyCategoryList } from "../../store";
import { DeleteOutline, EditOutlined, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined } from "@mui/icons-material";
import PositionedMenu from "./PositionedMenu";
import useCheckLogin, { checkLogin } from "../login/checkLogin";
import Join from "../login/Join";

// 모듈을 한 번만 등록
if (!Quill.imports['modules/imageResize']) {
  Quill.register('modules/imageResize', ImageResize);
}
// 폰트 옵션 추가
const Font = Quill.import('formats/font');
Font.whitelist = ['my-font', 'sans-serif', 'serif'];
Quill.register(Font, true);

const modules = {
  toolbar: false,
  clipboard: {
    matchVisual: true
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
};

const Post = () => {
  const location = useLocation();
  const { docId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [readPost, setReadPost] = useAtom(readPostAtom);
  const [post, setPost] = useState({});
  const [postReaction, setPostReaction] = useState(''); // Good/bad 반응 상태
  const [commentsReaction, setCommentsReaction] = useState({}); // 댓글 반응 상태를 객체로 관리
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [commentProgress, setCommentProgress] = useState(false);
  const [category, setCategory] = useState();
  const [showModal, setShowModal] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const categoryKey = queryParams.get('category');

  useEffect(() => {
    if (categoryKey) {
      if (categoryKey === '0') {
        setCategory(null);
      } else {
        setCategory(categoryKey);
      }
    }
  }, [categoryKey]);

  useEffect(() => {
    if (user != null) {
      onClickSearch();
      onClickSearchReaction();
      onClickCommentsSearch();
      onClickSearchCommentsReaction();

      if (!readPost.includes(docId)) {
        handleReadPost();
        setReadPost([...readPost, docId]);
      }
    }
  }, [user]);

  const closeModal = () => {
    setShowModal(false);
  };

  const onClickSearch = () => {
    const searchParams = new URLSearchParams();
    searchParams.append("docId", docId);

    setIsLoading(true);

    CustomAxios(`/getPost?${searchParams.toString()}`)
      .then((result) => {
        if (result.status === 200) {
          const res = result.data;
          setPost(res.result);
        }
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const onClickSearchReaction = () => {
    const searchParams = new URLSearchParams();
    searchParams.append("docId", docId);
    searchParams.append("uid", user.uid);

    setIsLoading(true);

    CustomAxios(`/getPostReaction?${searchParams.toString()}`)
      .then((result) => {
        if (result.status === 200) {
          const res = result.data;
          setPostReaction(res.result.type);
        }
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const onClickSearchCommentsReaction = () => {
    const searchParams = new URLSearchParams();
    searchParams.append("docId", docId);
    searchParams.append("uid", user.uid);

    setIsLoading(true);

    CustomAxios(`/getCommentsReaction?${searchParams.toString()}`)
      .then((result) => {
        if (result.status === 200) {
          const res = result.data;
          const reactionMap = res.result.reduce((acc, reaction) => {
            acc[reaction.commentId] = reaction.type;
            return acc;
          }, {});
          setCommentsReaction(reactionMap);
        }
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const onClickCommentsSearch = (page = 1) => {
    const searchParams = new URLSearchParams();
    searchParams.append("page", page);
    searchParams.append("docId", docId);

    setIsLoading(true);

    CustomAxios(`/getComments?${searchParams.toString()}`)
      .then((result) => {
        if (result.status === 200) {
          const res = result.data;
          setComments(res.result);
        }
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleReadPost = () => {
    CustomAxios(`/addReadCount`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ docId, uid: user.uid }),
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onClickCommentBlock = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }
  
  }, [user]);

  const onClickCommentReport = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }
  
  }, [user]);

  const onClickDelete = async (event) => {
    if (window.confirm("삭제하시겠습니까?") === false) {
      return;
    }
    
    setIsLoading(true);

    CustomAxios(`/deletePost`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ docId, nick_name: user.nick_name })
    })
      .then(() => {
        alert("삭제 되었습니다.");
        onClickPosts();
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddComment = (newCommentJson) => {
    setComments([...comments, newCommentJson]);
    setNewComment('');
  };

  function onClickPosts() {
    navigate(`/posts?category=${category}`);
  }

  function goPageModify() {
    navigate(`/postModify/${docId}`);
  };

  const onClickGood = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    let action, type;
    if (postReaction === 'good') {
      action = '';
      type = '삭제';
    } else if (postReaction === 'bad') {
      action = 'good';
      type = '변경';
    } else {
      action = 'good';
      type = '신규';
    }

    try {
      await CustomAxios(`/toggleGood`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ uid: user.uid, docId, action }),
      });

      setPostReaction(action);

      setPost(prevPost => {
        const updatedPost = { ...prevPost };
        if (type === "신규") {
          updatedPost.good_count += 1;
        } else if (type === "변경") {
          updatedPost.good_count += 1;
          updatedPost.bad_count -= 1;
        } else if (type === "삭제") {
          updatedPost.good_count -= 1;
        }
        return updatedPost;
      });

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [user, docId, postReaction, setIsLoading, setPost]);

  const onClickBad = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    let action, type;
    if (postReaction === 'bad') {
      action = '';
      type = '삭제';
    } else if (postReaction === 'good') {
      action = 'bad';
      type = '변경';
    } else {
      action = 'bad';
      type = '신규';
    }

    try {
      await CustomAxios(`/toggleBad`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ uid: user.uid, docId, action }),
      });

      setPostReaction(action);

      setPost(prevPost => {
        const updatedPost = { ...prevPost };
        if (type === "신규") {
          updatedPost.bad_count += 1;
        } else if (type === "변경") {
          updatedPost.bad_count += 1;
          updatedPost.good_count -= 1;
        } else if (type === "삭제") {
          updatedPost.bad_count -= 1;
        }
        return updatedPost;
      });

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [user, docId, postReaction, setIsLoading, setPost]);

  const onClickCommentSave = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    if (!newComment.trim()) {
      alert("댓글 작성 후 저장 해 주세요.");
      return;
    }

    setCommentProgress(true);

    try {
      const response = await CustomAxios(`/addComment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ uid: user.uid, nick_name: user.nick_name, docId, content: newComment }),
      });

      if (response.status === 201) {
        const { result } = response.data;
        handleAddComment(result);
        onClickCommentsSearch();
      } else {
        console.error(`Unexpected response status: ${response.status}`);
      }

    } catch (error) {
      console.error('Error saving comment:', error);
    } finally {
      setCommentProgress(false);
    }
  }, [user, docId, newComment, setCommentProgress]);

  const onClickCommentDelete = useCallback(async (event, commentId) => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    if (window.confirm("삭제하시겠습니까?") === false) {
      return;
    }

    setIsLoading(true);

    try {
      await CustomAxios(`/deleteComment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ docId, commentId, nick_name: user.nick_name }),
      });

      alert("삭제 되었습니다.");
      onClickCommentsSearch();

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [user, docId, setIsLoading]);

  // 리액션 업데이트 함수
  const updateCommentReaction = (commentId, newReaction) => {
    setCommentsReaction(prevReactions => ({
      ...prevReactions,
      [commentId]: newReaction
    }));
  };

  const onClickCommentGood = useCallback(async (event, commentId) => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    let action = "good";
    let type = "신규";

    const currentCommentReaction = commentsReaction[commentId] || '';
    if (currentCommentReaction === 'good') {
      action = '';
      type = "삭제";
    } else if (currentCommentReaction === 'bad') {
      action = 'good';
      type = '변경';
    }

    try {
      await CustomAxios(`/toggleCommentGood`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ uid: user.uid, docId, commentId, action }),
      });

      updateCommentReaction(commentId, action);

      setComments(prevComments => prevComments.map(comment => {
        if (comment.id === commentId) {
          const updatedComment = { ...comment };
          if (type === "신규") {
            updatedComment.good_count += 1;
          } else if (type === "변경") {
            updatedComment.good_count += 1;
            updatedComment.bad_count -= 1;
          } else if (type === "삭제") {
            updatedComment.good_count -= 1;
          }
          return updatedComment;
        }
        return comment;
      }));

    } catch (error) {
      console.error('Error updating comment reaction:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user, docId, commentsReaction]);

  const onClickCommentBad = useCallback(async (event, commentId) => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    let action = "bad";
    let type = "신규";

    const currentCommentReaction = commentsReaction[commentId] || '';
    if (currentCommentReaction === 'bad') {
      action = '';
      type = "삭제";
    } else if (currentCommentReaction === 'good') {
      action = 'bad';
      type = '변경';
    }

    try {
      await CustomAxios(`/toggleCommentBad`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ uid: user.uid, docId, commentId, action }),
      });

      updateCommentReaction(commentId, action);

      setComments(prevComments => prevComments.map(comment => {
        if (comment.id === commentId) {
          const updatedComment = { ...comment };
          if (type === "신규") {
            updatedComment.bad_count += 1;
          } else if (type === "변경") {
            updatedComment.bad_count += 1;
            updatedComment.good_count -= 1;
          } else if (type === "삭제") {
            updatedComment.bad_count -= 1;
          }
          return updatedComment;
        }
        return comment;
      }));

    } catch (error) {
      console.error('Error updating comment reaction:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user, docId, commentsReaction]);

  const handleLoginRedirect = () => {
    window.location.href = "/login";
  };

  return (
    <>
      <Box my={2}>
        {/* 첫 줄: 카테고리, 타이틀 */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" component="div" mr={1}>
              카테고리
            </Typography>
            <Typography variant="subtitle2" component="div">
              {dummyCategoryList.find(item => item.key === post.category)?.value || ''}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="div">
            {post.title}
          </Typography>
        </Box>

        {/* 두 번째 줄: 작성자, 작성시간 */}
        <Box display="flex" alignItems="center" mt={1} mb={1} justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="text.secondary" mr={1}>
              작성자
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {post.created_by}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="text.secondary" mr={1}>
              작성시간
            </Typography>
            {post?.created_at?._seconds && <TimestampFormatter timestampSeconds={post.created_at._seconds} />}
          </Box>
        </Box>

        {/* 세 번째 줄: 내용 */}
        <ReactQuill
          theme="snow"
          modules={modules}
          value={post.content}
          readOnly={true}
          className="custom-quill-editor"
        />

        {/* 네 번째 줄: Good, Bad 버튼 */}
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <Button
            variant="outlined"
            startIcon={postReaction === "good" ? <ThumbUp /> : <ThumbUpOutlined />}
            color="success"
            sx={{ mr: 2, ...(postReaction === "good" && { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }) }}
            onClick={onClickGood}
          >
            <Typography variant='button'>Good {post.good_count}</Typography>
          </Button>
          <Button
            variant="outlined"
            startIcon={postReaction === "bad" ? <ThumbDown /> : <ThumbDownOutlined />}
            color="error"
            sx={{ ...(postReaction === "bad" && { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }) }}
            onClick={onClickBad}
          >
            <Typography variant='button'>Bad {post.bad_count}</Typography>
          </Button>
        </Box>

        <Divider sx={{ my: 2 }} />

                <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={onClickPosts}><Typography variant='button'>목록</Typography></Button>
          <Box sx={{ my: 0, display: 'flex', justifyContent: 'space-between' }}>
            {
              user?.uid === post?.uid &&
              <>
                <Button variant="outlined" startIcon={<DeleteOutline />} onClick={onClickDelete}
                sx={{ mr: '10px;' }}><Typography variant='button'>삭제</Typography></Button>
                <Button variant="outlined" startIcon={<EditOutlined />} onClick={goPageModify}><Typography variant='button'>수정모드</Typography></Button>
              </>
            }
          </Box>
        </Box>

        <Typography variant="subtitle1" component="div" mb={2}>
          댓글
        </Typography>

        {/* 댓글 목록 */}
        <List>
          {
            comments ? comments.map((comment, index) => (
              <React.Fragment key={comment.id}>
                <Box my={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle2" component="div" mr={1}>
                        {comment.created_by}
                      </Typography>
                      {
                        comment?.created_at?._seconds
                          ? (
                            <TimestampFormatter timestampSeconds={comment.created_at._seconds} />
                          )
                          : <span>방금</span>
                      }
                    </Box>
                    <PositionedMenu
                      comment={comment}
                      onClickCommentDelete={onClickCommentDelete}
                      onClickCommentBlock={onClickCommentBlock}
                      onClickCommentReport={onClickCommentReport}
                    />
                  </Box>
                  <Typography variant="body1" component="div">
                    {comment.content}
                  </Typography>

                  <Box display="flex" justifyContent="end" alignItems="center">
                    <Box>
                      <Button
                        startIcon={commentsReaction[comment.id] === 'good' ? <ThumbUp /> : <ThumbUpOutlined />}
                        color='success'
                        variant='text'
                        sx={{
                          ...(commentsReaction[comment.id] === "good" && { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }) // 그림자 효과 추가
                        }}
                        onClick={(e) => onClickCommentGood(e, comment.id)}
                      >
                        {comment.good_count || 0}
                      </Button>
                      <Button
                        startIcon={commentsReaction[comment.id] === 'bad' ? <ThumbDown /> : <ThumbDownOutlined />}
                        color="error"
                        variant='text'
                        sx={{
                          ...(commentsReaction[comment.id] === "bad" && { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }) // 그림자 효과 추가
                        }}
                        onClick={(e) => onClickCommentBad(e, comment.id)}
                      >
                        {comment.bad_count || 0}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                {index < comments.length && <Divider />}
              </React.Fragment>
            ))
              : <CircularProgress />
          }
        </List>

        <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          {/* 댓글 입력 필드 */}
          <TextField
            label="댓글 입력"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            sx={{ mb: 2, flexGrow: 1, mr: 2 }}
          />

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (!user?.nick_name) {
                const confirmLogin = window.confirm("로그인이 필요합니다. 로그인 하시겠습니까?");
                if (confirmLogin) {
                  handleLoginRedirect();
                }
              } else {
                onClickCommentSave();
              }
            }}
            sx={{ width: '100px', height: '125px', whiteSpace: 'nowrap' }}
          >
            {commentProgress ? <CircularProgress color="inherit" /> : <Typography variant='button'>댓글저장</Typography>}
          </Button>
        </Box>
      </Box>
      
      <Modal open={showModal} onClose={closeModal}>
        <Box>
          <Join setShowModal={setShowModal} />
        </Box>
      </Modal>
    </>
  );
};

export default Post; 