import React from "react";
import { TextField } from "@mui/material";

const FormTextField = ({ label, value, onChange, type = "text", ...props }) => (
  <TextField
    sx={{ mt: "10px" }}
    variant="outlined"
    fullWidth
    size="small"
    label={label}
    value={value}
    onChange={onChange}
    type={type}
    InputLabelProps={{ shrink: true }}
    {...props}
  />
);

export default FormTextField;