import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container, Typography, Grid, Card, CardContent, CardMedia, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import CustomAxios from "../common/CustomAxios";
import { useAtom } from "jotai";
import { isLoadingAtom, userAtom } from "../../store";
import SearchIcon from '@mui/icons-material/Search';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { useNavigate } from "react-router-dom";

const RecordList = () => {
  const [user] = useAtom(userAtom);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecords = async () => {
      if (!user) return; // user 정보가 로드된 후 실행

      try {
        const searchParams = new URLSearchParams();
        searchParams.append("uid", user.uid);

        const response = await CustomAxios(`/getRecords?${searchParams.toString()}`);
        if (response.data.result === "SUCCESS") {
          // `eventDate` 값을 변환
          const recordsWithParsedDate = response.data.records.map(record => ({
            ...record,
            eventDate: parseEventDate(record.eventDate)
          }));
          setRecords(recordsWithParsedDate);
          setFilteredRecords(recordsWithParsedDate); // 필터링 초기값
        } else {
          console.error(response.data.error);
        }
      } catch (error) {
        console.error("🔥 Error fetching marathon records:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchRecords();
  }, [user]);

  useEffect(() => {
    // 필터링 수행
    const filtered = records.filter(record =>
      record.eventName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      new Date(record.eventDate).toLocaleDateString().includes(searchQuery) ||
      record.distance.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredRecords(filtered);
  }, [searchQuery, records]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
        <Typography variant="h4" gutterBottom>내 기록 목록</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/recordCreate')}>
          등록
        </Button>
      </Box>
      
      <TextField
        label="검색"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        margin="normal"
      />

      <Grid container spacing={3}>
        {filteredRecords.map(record => (
          <Grid item key={record.id} xs={12} sm={6} md={4}>
            <Card>
              {record.firstImage ? (
                <CardMedia
                  component="img"
                  height="140"
                  image={record.firstImage}
                  alt={`${record.eventName} 마라톤 이미지`}
                />
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="140" bgcolor="#f0f0f0">
                  <NoPhotographyIcon fontSize="large" />
                  <Typography variant="body2" color="textSecondary">No Image</Typography>
                </Box>
              )}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {record.eventName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  날짜: {record.eventDate}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  거리: {record.distance}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  기록: {record.time}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

// 숫자 형태의 `eventDate`를 JavaScript Date 객체로 변환하는 함수
const parseEventDate = (dateNumber) => {
  const dateString = dateNumber.toString();
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6); // 월은 0-11의 범위를 가집니다.
  const day = dateString.slice(6, 8);
  return `${year}-${month}-${day}`;
};

export default RecordList;