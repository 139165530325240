import React, { useState } from 'react';
import { Modal, Box, Typography, Divider, Button, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

const shoeModalStyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4,
};

const ShoeViewModal = ({ open, handleClose, shoe, onDelete, onUpdate, initialUseYN, initialDefaultYN, initialDistance, initialPurchaseDate }) => {
  const [shoeName, setShoeName] = useState(shoe.name);
  const [useYN, setUseYN] = useState(initialUseYN);
  const [defaultYN, setDefaultYN] = useState(initialDefaultYN);
  const [distance, setDistance] = useState(initialDistance);
  const [purchaseDate, setPurchaseDate] = useState(initialPurchaseDate);

  const handleSubmit = async () => {
    await onUpdate(shoe.id, shoeName, useYN, defaultYN, distance, purchaseDate);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={shoeModalStyle}>
        <Typography variant="h6" display="flex" justifyContent="space-between">
          신발 정보
          <Button variant="outlined" onClick={() => onDelete(shoe.id)}>
            삭제
          </Button>
        </Typography>
        <Divider sx={{ my: 2 }} />
        <TextField
          label="신발 이름"
          type="text"
          fullWidth
          sx={{ mt: 2 }}
          value={shoeName}
          onChange={(e) => setShoeName(e.target.value)}
        />
        <TextField
          label="신발 등록 전 주행거리 (km)"
          type="number"
          fullWidth
          sx={{ mt: 2 }}
          value={distance}
          onChange={(e) => setDistance(parseFloat(e.target.value))}
        />
        <TextField
          label="구매 시기"
          type="date"
          fullWidth
          sx={{ mt: 2 }}
          value={purchaseDate}
          onChange={(e) => setPurchaseDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>사용 여부</InputLabel>
          <Select 
            value={useYN} 
            onChange={(e) => setUseYN(e.target.value)}
            label="사용 여부"
          >
            <MenuItem value={"Y"}>Y</MenuItem>
            <MenuItem value={"N"}>N</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>기본 신발 여부</InputLabel>
          <Select 
            value={defaultYN} 
            onChange={(e) => setDefaultYN(e.target.value)}
            label="기본 신발 여부"
          >
            <MenuItem value={"Y"}>Y</MenuItem>
            <MenuItem value={"N"}>N</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" sx={{ mt: 2 }} fullWidth onClick={handleSubmit}>
          수정
        </Button>
      </Box>
    </Modal>
  );
};

export default ShoeViewModal;