import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingProgress from "./components/common/LoadingProgress";
import MainPage from "./page/MainPage";
import PostsPage from "./page/PostsPage";
import PostViewPage from "./page/PostViewPage";
import PostCreatePage from "./page/PostCreatePage";
import PostModifyPage from "./page/PostModifyPage";
import Login from "./components/login/Login";
import PrivacyPolicy250228v1 from "./components/policy/PrivacyPolicy250228v1";
import PersonalDataConsentForm250228v1 from "./components/policy/PersonalDataConsentForm250228v1";
import DiaryViewPage from "./page/DiaryViewPage";
import DiariesPage from "./page/DiariesPage";
import DiaryCreatePage from "./page/DiaryCreatePage";
import DiaryModifyPage from "./page/DiaryModifyPage";
import RecordCreate from "./components/record/RecordCreate";
import RecordList from "./components/record/RecordList";



export var ddf = 1;
const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/garminPrivacy" element={<PrivacyPolicy250228v1 />} />
          <Route path="/garminPrivacy2" element={<PersonalDataConsentForm250228v1 />} />
          <Route path="/login" element={<Login />} />
          <Route path="/posts" element={<PostsPage />} />
          <Route path="/posts/:docId" element={<PostViewPage />} />
          <Route path="/postCreate" element={<PostCreatePage />} />
          <Route path="/postModify/:docId" element={<PostModifyPage />} />

          <Route path="/diaries" element={<DiariesPage />} />
          <Route path="/diaries/:docId" element={<DiaryViewPage />} />
          <Route path="/diaryCreate" element={<DiaryCreatePage />} />
          <Route path="/diaryModify/:docId" element={<DiaryModifyPage />} />

          <Route path="/recordCreate" element={<RecordCreate />} />
          <Route path="/recordList" element={<RecordList />} />
          

          {/* <Route path="/callback/naver_login" element={<Callback />} />
          <Route path="/editor" element={<Editor />} />
          <Route path="/editor2" element={<Editor2 />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/2" element={<UserComponent nick_name={1} />} /> */}

        </Routes>
      </Router>
      <LoadingProgress />
    </>

  );
};

export default App;