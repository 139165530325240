import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Grid,
  Container,
  CircularProgress,
} from '@mui/material';
import { useAtom } from 'jotai';
import { isLoadingAtom, userAtom } from '../../store';
import CustomAxios from '../common/CustomAxios';
import { SendOutlined } from '@mui/icons-material';
import { checkLogin } from '../login/checkLogin';

import FormSelect from './create/FormSelect';
import FormTextField from './create/FormTextField';
import FormSelectMultiple from './create/FormSelectMultiple';
import ExerciseForm from './create/ExerciseForm';
import LinkForm from './create/LinkForm';
import ImageUpload from './create/ImageUpload';
import ShoeManagementModal from './create/ShoeManagementModal';

// 운동 타입 목록
const exerciseTypes = ['조깅', '장거리', '슬로우 조깅', '인터벌', '지속주', '기록체크', 'TT', '트레일러닝', '휴식', '부상'];

// 보강 운동 및 스트레칭 목록
const bodyweightExercises = ['스쿼트', '푸시업', '풀업', '런지', '버피 테스트', '사이드 런지', '플랭크', '사이드 플랭크', '레그 레이즈', '크런치', '브릿지', '슈퍼맨', '딥스', '폼롤러 스트레칭', '햄스트링 스트레칭', '와이드 스쿼트 스트레칭', '어깨 스트레칭', '고관절 스트레칭'];

// 상의 목록
const topItems = ['싱글렛', '반팔', '긴팔', '바람막이', '마스크', '고글', '모자'];

// 하의 목록
const bottomItems = ['2부 쇼츠', '3부 쇼츠', '4부 쇼츠', '5부 쇼츠', '반바지', '긴바지', '5부 레깅스', '9부 레깅스', '청바지', '면바지'];

// 날씨 목록
const weatherOptions = ['맑음', '흐림', '구름', '비', '눈', '미세먼지'];

const DiaryCreate = () => {
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [ready, setReady] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [type, setType] = useState(exerciseTypes[0]);
  const [content, setContent] = useState('');
  const [avgHeartRate, setAvgHeartRate] = useState('');
  const [avgPace, setAvgPace] = useState('');
  const [avgCadence, setAvgCadence] = useState('');
  const [temperature, setTemperature] = useState('');
  const [weather, setWeather] = useState(weatherOptions[0]);
  const [shoes, setShoes] = useState([]);
  const [shoeOptions, setShoeOptions] = useState([]); // 추가: 신발 옵션
  const [top, setTop] = useState(['반팔']);
  const [bottom, setBottom] = useState(['5부 쇼츠']);
  const [distance, setDistance] = useState(''); // 추가: 거리 정보

  const [startDate, setStartDate] = useState(() => {
    const currentDate = new Date();
    return currentDate.toISOString().slice(0, 10);
  });
  const [startTime, setStartTime] = useState(() => {
    const currentDate = new Date();
    return currentDate.toTimeString().slice(0, 5);
  });
  const [endDate, setEndDate] = useState(() => {
    const endTimeObj = new Date();
    return endTimeObj.toISOString().slice(0, 10);
  });
  const [endTime, setEndTime] = useState(() => {
    const endTimeObj = new Date();
    endTimeObj.setMinutes(endTimeObj.getMinutes() + 60);
    return endTimeObj.toTimeString().slice(0, 5);
  });

  const [exercises, setExercises] = useState([]);
  const [images, setImages] = useState([]);
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddShoeModal, setShowAddShoeModal] = useState(false); // 신발 추가 모달 상태 추가

  useEffect(() => {
    if (user) {
      setReady(true);
    }

    // 신발 목록 조회
    if (user && user.uid) {
      CustomAxios(`/getShoesByUser?uid=${user.uid}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }).then(response => {
        if (response.status === 200) {
          setShoeOptions(response.data.shoes);
          const defaultShoe = response.data.shoes.find(shoe => shoe.default === "Y");
          if (defaultShoe) {
            setShoes([defaultShoe.name]);
          }
        }
      }).catch(err => console.error(err));
    }
  }, [user]);

  const handleDrop = acceptedFiles => {
    const newImages = acceptedFiles.map((file, index) => ({
      id: `${file.name}-${Date.now()}-${index}`,
      file,
    }));

    setImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleImageRemove = index => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleMoveImageLeft = index => {
    if (index === 0) return;
    const newImages = [...images];
    [newImages[index], newImages[index - 1]] = [newImages[index - 1], newImages[index]];
    setImages(newImages);
  };

  const handleMoveImageRight = index => {
    if (index === images.length - 1) return;
    const newImages = [...images];
    [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
    setImages(newImages);
  };

  const onClickSave = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setLoading(true);

    const imageEntries = await Promise.all(
      images.map(async image => {
        const base64 = await fileToBase64(image.file);
        return { src: base64 };
      })
    );

    const postData = {
      type,
      content,
      startDate,
      startTime,
      endDate,
      endTime,
      distance: parseFloat(distance), // 추가: 거리
      avgHeartRate: parseInt(avgHeartRate),
      avgPace: parseInt(avgPace),
      avgCadence: parseInt(avgCadence),
      temperature: parseFloat(temperature),
      weather,
      shoes,
      top,
      bottom,
      exercises,
      links,
      images: imageEntries,
      uid: user.uid,
      nick_name: user.nick_name,
    };

    try {
      const response = await CustomAxios(`/createDiary`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(postData),
      });

      if (response.status === 201) {
        alert('등록 되었습니다.');
        navigate('/recordList'); // 예시 URL로 변경
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [user, type, content, startDate, startTime, endDate, endTime, distance, avgHeartRate, avgPace, avgCadence, temperature, weather, shoes, top, bottom, exercises, links, images]);

  const onClickHistoryBack = () => {
    navigate(-1);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  if (!ready) {
    return <div></div>;
  }

  return (
    <Container>
      <ShoeManagementModal 
        open={showAddShoeModal}
        handleClose={() => setShowAddShoeModal(false)}
        uid={user.uid}
        setShoes={setShoes}
      />

      <Box my={2}>
        <Typography variant="h6">훈련일지</Typography>

        <Divider sx={{ my: 2 }} />

        <FormSelect
          label="날씨"
          value={weather}
          options={weatherOptions}
          onChange={(e) => setWeather(e.target.value)}
        />

        <FormTextField 
          label="기온 (°C)"
          type="number"
          placeholder="11"
          value={temperature}
          onChange={(e) => setTemperature(e.target.value)}
        />

        <Divider sx={{ my: 2 }} />

        <FormSelect
          label="유형"
          value={type}
          options={exerciseTypes}
          onChange={(e) => setType(e.target.value)}
        />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormTextField 
              label="시작일자"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField 
              label="시작시간"
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormTextField 
              label="종료일자"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField 
              label="종료시간"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </Grid>
        </Grid>

        <FormTextField 
          label="거리 (km)"  // 추가: 거리 정보 입력 필드
          type="number"
          placeholder="10.0"
          value={distance}
          onChange={(e) => setDistance(parseFloat(e.target.value))}
        />

        <FormTextField 
          label="평균 페이스 (분/km)"
          type="number"
          placeholder="500"
          value={avgPace}
          onChange={(e) => setAvgPace(parseFloat(e.target.value))}
        />
        <FormTextField 
          label="평균 심박수 (bpm)"
          type="number"
          placeholder="130"
          value={avgHeartRate}
          onChange={(e) => setAvgHeartRate(parseInt(e.target.value))}
        />
        <FormTextField 
          label="평균 케이던스 (spm)"
          type="number"
          placeholder="180"
          value={avgCadence}
          onChange={(e) => setAvgCadence(parseInt(e.target.value))}
        />

        <FormTextField 
          label="소감"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />

        <Divider sx={{ my: 2 }} />

        <FormSelectMultiple
          label="상의"
          items={top}
          options={topItems}
          onAdd={() => setTop([...top, topItems[0]])}
          onRemove={(index) => setTop(top.filter((_, i) => i !== index))}
          onChange={(index, value) => setTop(top.map((item, i) => i === index ? value : item))}
        />

        <Divider sx={{ my: 2 }} />

        <FormSelectMultiple
          label="하의"
          items={bottom}
          options={bottomItems}
          onAdd={() => setBottom([...bottom, bottomItems[0]])}
          onRemove={(index) => setBottom(bottom.filter((_, i) => i !== index))}
          onChange={(index, value) => setBottom(bottom.map((item, i) => i === index ? value : item))}
        />

        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h7">신발</Typography>
          <Button variant="outlined" onClick={() => setShowAddShoeModal(true)}>관리</Button>
        </Box>

        <FormSelect
          label=""
          value={shoes.length > 0 ? shoes[0] : ""}
          options={shoeOptions.map(shoe => shoe.name)}
          onChange={(e) => setShoes([e.target.value])}
        />

        <Divider sx={{ my: 2 }} />

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h6">보강운동</Typography>
          <Button variant="outlined" onClick={() => setExercises([...exercises, { name: bodyweightExercises[0], reps: '100', sets: '1' }])}>
            추가
          </Button>
        </Box>
        <ExerciseForm 
          exercises={exercises}
          bodyweightExercises={bodyweightExercises}
          handleExerciseChange={(index, e) => setExercises(
            exercises.map((exercise, i) => i === index ? { ...exercise, [e.target.name]: e.target.value } : exercise)
          )}
          handleRemoveExercise={(index) => setExercises(exercises.filter((_, i) => i !== index))}
        />

        <Divider sx={{ my: 2 }} />

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h6">관련 링크 추가</Typography>
          <Box sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={() => setLinks([...links, { url: "" }])}>
              추가
            </Button>
          </Box>
        </Box>
        <LinkForm 
          links={links}
          handleLinkChange={(index, e) => setLinks(
            links.map((link, i) => i === index ? { ...link, url: e.target.value } : link)
          )}
          handleRemoveLink={(index) => setLinks(links.filter((_, i) => i !== index))}
        />

        <Divider sx={{ my: 2 }} />

        <ImageUpload 
          images={images}
          setImages={setImages}
          handleDrop={handleDrop}
          handleImageRemove={handleImageRemove}
          handleMoveImageLeft={handleMoveImageLeft}
          handleMoveImageRight={handleMoveImageRight}
        />

        <Box sx={{ my: 2, display: "flex", justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={onClickHistoryBack}>
            <Typography variant="button">이전</Typography>
          </Button>
          <Button
            variant="outlined"
            startIcon={<SendOutlined />}
            onClick={onClickSave}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "등록"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default DiaryCreate;