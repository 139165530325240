import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Modal,
  List,
  CircularProgress,
} from '@mui/material';
import TimestampFormatter from "../common/TimestampFormatter";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageResize from 'quill-image-resize';
import CustomAxios from "../common/CustomAxios";
import { useAtom } from "jotai";
import { isLoadingAtom, userAtom, dummyCategoryList } from "../../store";
import { DeleteOutline, EditOutlined, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined } from "@mui/icons-material";
import Join from "../login/Join";

// 모듈을 한 번만 등록
if (!Quill.imports['modules/imageResize']) {
  Quill.register('modules/imageResize', ImageResize);
}
// 폰트 옵션 추가
const Font = Quill.import('formats/font');
Font.whitelist = ['my-font', 'sans-serif', 'serif'];
Quill.register(Font, true);

const modules = {
  toolbar: false,
  clipboard: {
    matchVisual: true
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
};

const Diary = () => {
  const location = useLocation();
  const { docId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [diary, setDiary] = useState({});
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [commentProgress, setCommentProgress] = useState(false);
  const [category, setCategory] = useState();
  const [showModal, setShowModal] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const categoryKey = queryParams.get('category');

  useEffect(() => {
    if (categoryKey) {
      if (categoryKey === '0') {
        setCategory(null);
      } else {
        setCategory(categoryKey);
      }
    }
  }, [categoryKey]);

  useEffect(() => {
    if (user?.uid) {
      getDiary();
    }
  }, [user]);

  const closeModal = () => {
    setShowModal(false);
  };

  const getDiary = () => {
    const searchParams = new URLSearchParams();
    searchParams.append("uid", user.uid);
    searchParams.append("docId", docId);

    setIsLoading(true);

    CustomAxios(`/getDiary?${searchParams.toString()}`)
      .then((result) => {
        if (result.status === 200) {
          const res = result.data;
          setDiary(res.result);
        }
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const onClickDelete = async (event) => {
    if (window.confirm("삭제하시겠습니까?") === false) {
      return;
    }
    
    setIsLoading(true);

    CustomAxios(`/deleteDiary`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ docId, uid: user.uid })
    })
      .then(() => {
        alert("삭제 되었습니다.");
        onClickDiaries();
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  function onClickDiaries() {
    navigate(`/diaries`);
  }

  function goPageModify() {
    navigate(`/diaryModify/${docId}`);
  };

  return (
    <>
      <Box my={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" component="div" mr={1}>
              카테고리
            </Typography>
            <Typography variant="subtitle2" component="div">
              {dummyCategoryList.find(item => item.key === diary.category)?.value || ''}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="div">
            {diary.title}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mt={1} mb={1} justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="text.secondary" mr={1}>
              작성자
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {diary.created_by}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="text.secondary" mr={1}>
              작성시간
            </Typography>
            {diary?.created_at?._seconds && <TimestampFormatter timestampSeconds={diary.created_at._seconds} />}
          </Box>
        </Box>

        <ReactQuill
          theme="snow"
          modules={modules}
          value={diary.content}
          readOnly={true}
          className="custom-quill-editor"
        />

        <Divider sx={{ my: 2 }} />

        <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={onClickDiaries}><Typography variant='button'>목록</Typography></Button>
          <Box sx={{ my: 0, display: 'flex', justifyContent: 'space-between' }}>
            {user?.uid === diary?.uid && (
              <>
                <Button variant="outlined" startIcon={<DeleteOutline />} onClick={onClickDelete} sx={{ mr: '10px' }}><Typography variant='button'>삭제</Typography></Button>
                <Button variant="outlined" startIcon={<EditOutlined />} onClick={goPageModify}><Typography variant='button'>수정모드</Typography></Button>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Modal open={showModal} onClose={closeModal}>
        <Box>
          <Join setShowModal={setShowModal} />
        </Box>
      </Modal>
    </>
  );
};

export default Diary;