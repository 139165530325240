import React, { useState } from 'react';
import { Toolbar } from 'react-big-calendar';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

const CustomToolbar = (toolbar) => {
  const [activeView, setActiveView] = useState(toolbar.view || 'month');

  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  const label = () => {
    const date = new Date(toolbar.date);
    return format(date, 'yyyy년 M월', { locale: ko });
  };

  const viewStyle = (viewName) => ({
    backgroundColor: activeView === viewName ? 'lightblue' : undefined,
  });

  const handleViewChange = (viewName) => {
    setActiveView(viewName);
    toolbar.onView(viewName);
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>이전</button>
        <button type="button" onClick={goToCurrent}>오늘</button>
        <button type="button" onClick={goToNext}>다음</button>
      </span>
      <span className="rbc-toolbar-label">{label()}</span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => handleViewChange('month')} style={viewStyle('month')}>월</button>
        <button type="button" onClick={() => handleViewChange('week')} style={viewStyle('week')}>주</button>
        <button type="button" onClick={() => handleViewChange('day')} style={viewStyle('day')}>일</button>
        <button type="button" onClick={() => handleViewChange('agenda')} style={viewStyle('agenda')}>훈련일지</button>
      </span>
    </div>
  );
};

export default CustomToolbar;