import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Avatar, Button, Pagination, IconButton, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { recentPosts } from '../json/recentlyData';
import { useAtom } from "jotai";
import { dummyCategoryList, isLoadingAtom, userAtom } from "../../store";
import CustomAxios from '../common/CustomAxios';
import TimestampFormatter from '../common/TimestampFormatter';
import { ArrowForward, ChevronRightOutlined, ImageOutlined, LineWeight, MoreHorizOutlined, MoreOutlined, MoreSharp, MoveDown, MoveToInbox, MoveToInboxOutlined, MovieFilterOutlined } from '@mui/icons-material';
import NumberWithComma from '../common/NumberWithComma';
import MainPosts from './MainPosts';
import MainPostsNoResult from './MainPostsNoResult';

const POSTS_PER_PAGE = 10;

const PopularPosts = () => {
    const navigate = useNavigate();
    const [user, setUser] = useAtom(userAtom);
    const [, setIsLoading] = useAtom(isLoadingAtom);
    const [posts, setPosts] = useState([]);
    const [totalCount, setTotalCount] = useState(1);

    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    // const totalPages = Math.ceil(recentPosts.length / POSTS_PER_PAGE);

    const startIndex = (currentPage - 1) * POSTS_PER_PAGE;
    const endIndex = startIndex + POSTS_PER_PAGE;
    // const displayedPosts = filteredPosts.slice(startIndex, endIndex);
    const totalPages = Math.ceil(totalCount / POSTS_PER_PAGE);

    useEffect(() => {
        if (user != null) {
            onClickSearch(1, null);
        }
    }, [user]);

    const handleChangePage = (event, value) => {
        setPage(value);
        onClickSearch(value, null);
    };

    const handleClick = (post) => {
        // 원하는 경로로 이동하게끔 설정합니다.
        navigate(`/posts/${post.id}?category=${post.category}`);
    };

    // const displayedPosts = recentPosts.slice(
    //     (page - 1) * POSTS_PER_PAGE,
    //     page * POSTS_PER_PAGE
    // );

    // Function to get posts
    function onClickSearch(page = 1, category = '0') {
        const searchParams = new URLSearchParams();
        searchParams.append("page", page);
        searchParams.append("category", category);
        searchParams.append("posts_per_page", POSTS_PER_PAGE);


        setIsLoading(true);

        CustomAxios(`/getPostsOfBest?${searchParams.toString()}`)
            .then((result) => {
                const res = result.data;
                setPosts(res.result);
                setTotalCount(res.totalCount);
                setIsLoading(false);

            }).catch((err) => {
                console.error(err);
                setIsLoading(false);
            });
    }

    const movePage = () => {
        navigate(`/posts`);
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1">
                    인기글
                </Typography>
                <IconButton
                    onClick={movePage}
                    color="inherit"
                    size="small"
                >
                    <ChevronRightOutlined />
                </IconButton>
            </Box>
            <TableContainer
                component={Paper}
                sx={{
                    minHeight: '370px', // 리스트의 높이를 제한합니다.
                    marginBottom: '20px', // 페이징 영역과의 간격을 유지합니다.
                }}
            >
                <Table size="small">
                    <TableBody>
                        {posts.map((post, index) => (
                            <MainPosts post={post} key={index} index={index} handleClick={handleClick} />
                        ))}
                        {totalCount === 0 && (
                            <MainPostsNoResult />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {posts.length > 0 && (
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                    sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}

                    siblingCount={1}
                    boundaryCount={1}
                    // variant="outlined"
                    shape="rounded"
                />
            )}
        </>
    );
};

export default PopularPosts;