import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Avatar, Button, Pagination, IconButton, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAtom } from "jotai";
import { isLoadingAtom, userAtom } from "../../store";
import CustomAxios from '../common/CustomAxios';
import TimestampFormatter from '../common/TimestampFormatter';
import { CreateOutlined, ImageOutlined } from '@mui/icons-material';
import NumberWithComma from '../common/NumberWithComma';
import MyCalendar from '../diary/MyCalendar';

const DIARIES_PER_PAGE = 10;

const TrainingDiary = () => {
    const navigate = useNavigate();
    const [user, setUser] = useAtom(userAtom);
    const [, setIsLoading] = useAtom(isLoadingAtom);
    const [diaries, setDiaries] = useState([]);
    const [totalCount, setTotalCount] = useState(1);
    const [page, setPage] = useState(1);

    const totalPages = Math.ceil(totalCount / DIARIES_PER_PAGE);

    const getDiaries = useCallback((page = 1) => {
        if (!user?.uid) return;
        
        const searchParams = new URLSearchParams();
        searchParams.append("page", page);
        searchParams.append("uid", user.uid);
    
        setIsLoading(true);
    
        CustomAxios(`/getDiaries?${searchParams.toString()}`)
          .then((result) => {
            const res = result.data;
            setDiaries(res.result);
            setTotalCount(res.totalCount);
            setIsLoading(false);
          }).catch((err) => {
            console.error(err);
            setIsLoading(false);
          });
      }, [user, setIsLoading]);
    
    useEffect(() => {
        // user가 로드 완료된 후에만 getDiaries 호출
        if (user?.uid) {
          getDiaries();
        }
      }, [user, getDiaries]);
    
    const handlePageChange = (event, value) => {
        setPage(value);
        getDiaries(value);
    };

    const handleRowClick = (diaryId) => {
        navigate(`/diaries/${diaryId}`);
    };

    const moveCreateDiaryPage = () => {
        navigate(`/diaryCreate`);
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1">
                    나의 훈련 일지
                </Typography>
                <IconButton
                    onClick={moveCreateDiaryPage}
                    color="inherit"
                    size="small"
                >
                    <CreateOutlined />
                </IconButton>
            </Box>
            {user?.uid && (
                <MyCalendar uid={user.uid} />
            )}
            <TableContainer
                component={Paper}
                sx={{
                    minHeight: '370px', // 리스트의 높이를 제한합니다.
                    marginBottom: '20px', // 페이징 영역과의 간격을 유지합니다.
                }}
            >
                <Table size="small">
                    <TableHead >
                    <TableRow >
                        <TableCell align="center">제목</TableCell>
                        <TableCell sx={{ width: '80px' }} padding="none" align="center">글쓴이</TableCell>
                        <TableCell sx={{ width: '80px' }} padding="none" align="center"> 등록일</TableCell>
                        <TableCell sx={{ width: '10%' }} padding="none" align="center">조회</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody >
                    {diaries.map((diary, index) => (
                        <TableRow
                        key={diary.id}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:hover': {
                            backgroundColor: 'rgba(25, 101, 192, 0.3)', // 원하는 색상으로 변경
                            },
                            backgroundColor: index % 2 === 0 ? 'rgba(25, 101, 192, 0.1)' : 'transparent'
                        }}
                        >
                        <TableCell
                            onClick={() => handleRowClick(diary.id)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <Box display='flex' alignItems='center'>
                            {diary.have_image && <ImageOutlined color="primary" />}
                            {diary.title}{diary.comment_count ?
                                <Typography
                                variant="caption"
                                sx={{
                                    color: "#404040",
                                    backgroundColor: "#a0f0f0",
                                    fontWeight: 600,
                                    display: 'inline-block',
                                    margin: '3px 0 0 6px',
                                    padding: 0,
                                    verticalAlign: 'top'
                                }}
                                >
                                {diary.comment_count}
                                </Typography> : null}
                            </Box>
                        </TableCell>
                        <TableCell sx={{ width: '80px' }} padding="none" align="center">{diary.created_by}</TableCell>
                        <TableCell sx={{ width: '80px' }} padding="none" align="center"><TimestampFormatter timestampSeconds={diary.created_at._seconds} /></TableCell>
                        <TableCell sx={{ width: '100px' }} padding="none" align="center"><NumberWithComma number={diary.read_count} /></TableCell>
                        </TableRow>
                    ))}
                    {totalCount === 0 && (
                        <TableRow>
                        <TableCell colSpan={5} align="center">
                            검색 결과가 없습니다.
                        </TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
            {diaries.length > 0 && (
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                    siblingCount={1}
                    boundaryCount={1}
                    shape="rounded"
                />
            )}
        </>
    );
};

export default TrainingDiary;