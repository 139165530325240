import React from "react";
import { Box, Select, MenuItem, IconButton, Typography, Button } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const FormSelectMultiple = ({ label, items, options, onAdd, onRemove, onChange }) => (
    <Box> 
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>{label}</Typography>
            <Button variant="outlined" onClick={onAdd} >
            추가
            </Button>
        </Box>
    <Box mt={2}>
        {items.map((item, index) => (
        <Box key={index} display="flex" alignItems="center" mt={1}>
            <Select
            value={item}
            onChange={e => onChange(index, e.target.value)}
            size="small"
            fullWidth
            >
            {options.map((option, idx) => (
                <MenuItem key={idx} value={option}>
                {option}
                </MenuItem>
            ))}
            </Select>
            <IconButton onClick={() => onRemove(index)}>
            <CloseIcon />
            </IconButton>
        </Box>
        ))}
    </Box>
  </Box>
);

export default FormSelectMultiple;