import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import CustomAxios from "../../common/CustomAxios";

const shoeModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ShoeAddModal = ({ open, handleClose, uid, onSuccess }) => {
  const [shoeName, setShoeName] = useState("");
  const [useYN, setUseYN] = useState("Y");
  const [defaultYN, setDefaultYN] = useState("N");
  const [distance, setDistance] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    setShoeName("");
    setUseYN("Y");
    setDefaultYN("N");
    setDistance("");
    setPurchaseDate("");
  };

  const handleAddShoe = async () => {
    if (!shoeName.trim()) {
      alert("신발 이름을 입력해 주세요.");
      return;
    }

    setLoading(true);
    try {
      const response = await CustomAxios.post(`/addShoe`, {
        uid,
        name: shoeName,
        useYN,
        defaultYN,
        distance: parseFloat(distance),
        purchaseDate
      });
      if (response.status === 201) {
        onSuccess();
        alert("신발이 추가되었습니다.");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      resetState();
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={shoeModalStyle}>
        <Typography variant="h6">신발 추가</Typography>
        <Divider sx={{ my: 2 }} />
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="신발 이름"
          value={shoeName}
          onChange={(e) => setShoeName(e.target.value)}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>사용 여부</InputLabel>
          <Select
            value={useYN}
            onChange={(e) => setUseYN(e.target.value)}
            label="사용 여부"
          >
            <MenuItem value={"Y"}>Y</MenuItem>
            <MenuItem value={"N"}>N</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>기본 신발 여부</InputLabel>
          <Select
            value={defaultYN}
            onChange={(e) => setDefaultYN(e.target.value)}
            label="기본 신발 여부"
          >
            <MenuItem value={"Y"}>Y</MenuItem>
            <MenuItem value={"N"}>N</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="신발 등록 전 주행거리 (km)"
          type="number"
          value={distance}
          onChange={(e) => setDistance(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="구매 시기"
          type="date"
          value={purchaseDate}
          onChange={(e) => setPurchaseDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{ mt: 2 }}
        />
        <Button
          variant="outlined"
          onClick={handleAddShoe}
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? "저장 중..." : "저장"}
        </Button>
      </Box>
    </Modal>
  );
};

export default ShoeAddModal;